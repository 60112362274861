import * as React from "react"

import { faChevronRight, faEye, faPhone } from "@fortawesome/free-solid-svg-icons"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  CardList,
  SimpleBanner,
  BannerTitle,
  HorizontalProfile,
  FaqList,
  Profile,
  CreativeContent,
  Button,
} from "@launchacademy/voyager"
import { graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"

import Configuration from "../../Configuration"
import { CourseScheduleList } from "../../components/courseSchedule/CourseScheduleList"
import { HalfPageParagraph } from "../../components/halfPageParagraphs/HalfPageParagraph"
import BookInfoCallButton from "../../components/infoCall/BookInfoCallButton"
import Layout from "../../components/layout"
import { Module } from "../../components/module/Module"
import { ModuleLearningObjective } from "../../components/module/ModuleLearningObjective"
import Seo from "../../components/seo"
import SyllabusModalButton from "../../components/syllabus/SyllabusModalButton"
import {
  collaborationContent,
  courseContent,
  tuitionContent,
  courseColumns,
  textColumns,
  tuitionColumns,
} from "../../pageData/programs/partTimeOnlinePageData"
import "./../css/program.css"
import { buildCohortProps } from "../../services/buildCohortProps"
import buildImage from "../../services/buildImage"
import "../../css/accent-images/program.css"
import "../../css/online.css"

const PartTimeOnline = ({ data, location }) => {
  const courseContentWithImages = courseContent.map((content) => {
    const imageDataForContent = data[content.id]
    return { ...content, Image: buildImage(imageDataForContent) }
  })

  const cohorts = buildCohortProps(data.virtualCohorts.nodes)

  const teamMemberListItems = (data.teamMemberSet?.team_members || []).map((teamMember) => {
    return (
      <HorizontalProfile
        key={teamMember.id}
        name={`${teamMember.firstName} ${teamMember.lastName}`}
        Image={buildImage(teamMember.headshot?.localFile)}
        currentTitle={teamMember.jobTitle}
        description={teamMember.bio}
        profileType="teamMember"
      />
    )
  })

  const collaborationContentWithImages = collaborationContent.map((content) => {
    const imageDataForContent = data[content.id]
    return { ...content, Image: buildImage(imageDataForContent) }
  })

  const tuitionContentWithImages = tuitionContent.map((content) => {
    const imageDataForContent = data[content.id]
    return { ...content, Image: buildImage(imageDataForContent) }
  })

  const featuredLauncher = data.launcherSet.featured_launchers[0]
  return (
    <Layout>
      <Seo
        title="Launch Academy's Part Time Online Software Development Program"
        description="Launch Academy offers a 9 month virtual program. Learn to code with JavaScript, NodeJS, Java, and ReactJS"
        pathname={location.pathname}
      />

      <section className="hero">
        <div className="hero__supporting-content">
          <h2 className="hero__image_subtitle">
            Embark on a part-time coding journey that leads to high-paying rewards.
          </h2>
          <h1 className="hero__image_title">Learn to code virtually, part time.</h1>
          <div className="hero__button-container">
            <Button
              to={Configuration.applicationBaseUrl}
              size="lg"
              text="Apply Today"
              icon={faChevronRight}
            />
          </div>
          <div className="hero__button-container">
            <BookInfoCallButton
              text="Schedule an Info Call"
              icon={faPhone}
              className="hero__button"
              location={location}
              secondary
              size="lg"
            />
          </div>
        </div>
        <div className="hero__image-column">
          <div className="hero__image-container">
            <GatsbyImage
              image={getImage(data.topLeftHeader)}
              className="hero__image hero__image_sm"
              alt="Student watching virtual lecture"
            />
          </div>
        </div>
      </section>
      <section className="part-time-value-stack">
        <ul className="fa-ul part-time-value-stack__list fa-ul">
          <li>
            <span className="part-time-value-stack__icon">
              <FontAwesomeIcon icon={faCheck} />
            </span>
            Master in-demand skills
          </li>
          <li>
            <span className="part-time-value-stack__icon">
              <FontAwesomeIcon icon={faCheck} />
            </span>
            Learn while fully employed
          </li>
          <li>
            <span className="part-time-value-stack__icon">
              <FontAwesomeIcon icon={faCheck} />
            </span>
            Accelerate career growth
          </li>
        </ul>
      </section>
      <section className="virtual-stakes">
        <div className="virtual-stakes__image-container">
          <GatsbyImage image={getImage(data.frustratedKnowledgeWorker)} alt="Frustrated worker" />
        </div>
        <div className="virtual-stakes__content">
          <h3>Career growth shouldn't mean sacrificing your job</h3>
          <p>
            Balancing a full-time job while striving to break into the software development industry
            can feel like an impossible feat. You have responsibilities that can’t be put on hold to
            pursue the skills you need for the career you want. The time, effort, and resources
            required often discourage many aspiring techies from pursuing their passion.
          </p>
          <br />
          <p>That’s why we created our new part-time, online bootcamp option.</p>
          <br />
        </div>
      </section>
      <section className="virtual-success">
        <div className="virtual-success__content">
          <h3>Open the door to a lucrative tech career</h3>
          <p>
            Imagine gaining the expertise to become a sought-after full stack developer, all while
            maintaining your current job. All without losing any of the support and academic rigor
            of our full-time, on campus program includes. Plus, here at Launch Academy, graduation
            is just a milestone along your journey - we provide ongoing career services, up-to-date
            learning experiences, and a strong alumni network for all our graduates to make sure you
            are supported throughout your career.
          </p>

          <div className="virtual-success__button-container">
            <Button
              to={Configuration.applicationBaseUrl}
              size="md"
              text="Apply Today"
              icon={faChevronRight}
            />
            <BookInfoCallButton
              text="Schedule an Info Call"
              icon={faPhone}
              className="hero__button"
              location={location}
              secondary
              size="md"
            />
          </div>
        </div>
        <div className="virtual-success__image-container">
          <GatsbyImage image={getImage(data.professionalAtDesk)} alt="Professional at desk" />
        </div>
      </section>

      <section className="program-features card-list-container mt-20 z-10">
        <h2 className="mb-10">Practical coding skills for real world success</h2>
        <p>
          Our instructor-led program equips you with real-world software development skills. It’s a
          shorter, more practical alternative to a traditional computer science degree, designed to
          focus on hands-on application rather than just theory.
        </p>
        <CardList content={courseContentWithImages} numberOfColumns={courseColumns} />
      </section>
      <SimpleBanner
        type="prose"
        className="z-0"
        BackgroundImage={buildImage(data.agileCurriculumBackground)}
      >
        <BannerTitle>
          An agile curriculum updated for every incoming class so your skills will always match the
          latest market demands.
        </BannerTitle>
        <SyllabusModalButton
          location={location}
          text="Get the Syllabus"
          icon={faEye}
          className="mt-5 lg:mt-10"
          placement="photo"
          secondary
          size="lg"
        />
      </SimpleBanner>
      <div className="virtual-module-container">
        <Module
          subtitle="Module 1"
          title="JavaScript Fundamentals"
          location="Online"
          commitment="Part-Time"
          duration="9 Weeks"
          description="Learn the basics of programming with JavaScript object-oriented programming."
        >
          <ModuleLearningObjective objectiveDescription="Begin with understanding of basic syntax, data types and control flow." />
          <ModuleLearningObjective objectiveDescription="Navigate compound data structures with powerful native JS functions." />
          <ModuleLearningObjective objectiveDescription="Build interactive object-oriented applications with class syntax, methods and inheritance. " />
        </Module>
        <Module
          subtitle="Module 2"
          title="Java Object-Oriented Programming"
          location="Online"
          commitment="Part-Time"
          duration="9 Weeks"
          description="Expand your knowledge of object-oriented programming with powerful Java applications."
        >
          <ModuleLearningObjective objectiveDescription="Learn how to write programs that are statically typed." />
          <ModuleLearningObjective objectiveDescription="Build Java apps with key design patterns in mind, such as encapsulation, inheritance and polymorphism." />
          <ModuleLearningObjective objectiveDescription="Discuss implementations with peers and instructors to make robust object oriented apps." />
        </Module>
        <Module
          subtitle="Module 3"
          title="Server-Side Web Development "
          location="Online"
          commitment="Part-Time"
          duration="6 Weeks"
          description="Build your first dynamic web applications with core Java technologies."
        >
          <ModuleLearningObjective objectiveDescription="Learn the essentials of HTML and CSS to make beautifully designed websites." />
          <ModuleLearningObjective objectiveDescription="Utilize the server-side Java Servlets framework for handling web requests." />
          <ModuleLearningObjective objectiveDescription="Integrate JSP and Thymeleaf to make dynamic web pages using HTML and Java." />
        </Module>
        <Module
          subtitle="Module 4"
          title="Full-Stack Web Development Mastery"
          location="Online"
          commitment="Part-Time"
          duration="5 Weeks"
          description="Integrate each of the technologies you have learned into full-stack applications with client-side programming."
        >
          <ModuleLearningObjective objectiveDescription="Revisit the JavaScript language and make responsive front end applications in ReactJS." />
          <ModuleLearningObjective objectiveDescription="Learn asynchronous programming with fetch and your first API endpoints. " />
          <ModuleLearningObjective objectiveDescription="Develop with important industry tools for routing, authentication and data management. " />
        </Module>
        <Module
          subtitle="Module 5"
          title="API Development & Capstone Project"
          location="Online"
          commitment="Part-Time"
          duration="5 Weeks"
          description="Put everything you have learned together in robust web applications that utilize data-rich APIs and open source libraries. "
        >
          <ModuleLearningObjective objectiveDescription="Integrate third-party APIs such as Google, Yelp or Spotify." />
          <ModuleLearningObjective objectiveDescription="Learn how to manage your development process with Agile methodology. " />
          <ModuleLearningObjective objectiveDescription="Understand the importance of Git workflow to simulate working on real software projects." />
        </Module>
        <Module
          subtitle="Module 6"
          title="Career Advancement and Post-Grad Support"
          location="Online"
          commitment="Part-Time"
          duration="8 Weeks"
          description="After all of the preparation and study, you're ready to land your first job as a software developer."
        >
          <ModuleLearningObjective objectiveDescription="Present your portfolio projects to interested employers seeking to hire graduates." />
          <ModuleLearningObjective objectiveDescription="Develop a job search strategy with our career services team - from application to interview, to offer and negotiation." />
          <ModuleLearningObjective objectiveDescription="‘Lifetime Access to Alumni Support’ provides you with quarterly curriculum updates so you can continue learning on the job and position yourself for promotions. Come back to Launch Academy as a free workshare to build side projects with alumni and expand your local professional network." />
        </Module>
      </div>
      <section className="online-authority">
        <div className="online-authority__container">
          <h2 className="online-authority__headline">
            Navigating a career change? We’re here for you
          </h2>
          <p>
            As pioneers in the tech bootcamp industry, we understand the challenges and excitement
            of career change. We have a passion for software development and want to support you in
            finding your dream job in this exciting (and in demand) field. You might be looking for
            a change, or maybe learning how to code can make your current job easier. Either way,
            we've got your back.
          </p>
          <p>
            It is one of the reasons we created this new program - hear more about why we are
            launching it from our Founder, Dan Pickett.
          </p>
          <div className="online-authority__video-wrapper">
            <iframe
              src={`https://www.youtube.com/embed/1ajbwpYyuPs?autoplay=0`}
              title={"The Why Behind Our Virtual Part-Time Program"}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <p>
            <em>
              We want you to know we’re here to guide you through every step, providing rigorous
              training and ongoing support to ensure your success in the field.
            </em>
          </p>
        </div>
      </section>
      <div className="constrained-container ">
        <div className="banner-with-creative creative-content__container banner-with-creative_white">
          <HalfPageParagraph
            title="Land the job. Learn for life."
            className="my-4"
            text="Learning doesn’t end when you land your first job. Launch Academy graduates enjoy continuous curriculum access and updates so they can keep their skills up-to-date for changing market needs. They also have access to our Career Services support and connections, and best of call, an incredible alumni network - all for life!"
            subtitle="Kickstart your career with confidence"
            icon={faChevronRight}
          />
          {featuredLauncher && (
            <CreativeContent>
              <Profile
                name={`${featuredLauncher.firstName} ${featuredLauncher.lastName}`}
                Image={buildImage(featuredLauncher.headshot?.localFile)}
                currentTitle={featuredLauncher.currentJobTitle}
                currentEmployer={featuredLauncher.currentEmployer}
                formerTitle={featuredLauncher.previousTitle}
              />
            </CreativeContent>
          )}
        </div>
      </div>
      <div className="horizontal-profile-list__title constrained-container">
        <h2 className="mb-10">We get you. We've got you.</h2>
        <div className="horizontal-profile-list__container">{teamMemberListItems}</div>
      </div>
      <div className="virtual-part-time-process">
        <div className="virtual-part-time-process__container">
          <h2 className="virtual-part-time-process__headline">
            Your software engineering career roadmap, in 3 simple steps
          </h2>
          <ol className="virtual-part-time-process__list">
            <li>
              <h3>Step 1: Get Familiar</h3>
              <p>
                Attend expert instructor-led sessions and dive into our comprehensive curriculum.
              </p>
            </li>
            <li>
              <h3>Step 2: Get Serious</h3>
              <p>
                Hone your skills through hands-on projects and coding challenges, with continuous
                feedback on your progress.
              </p>
            </li>
            <li>
              <h3>Step 3: Get Paid</h3>
              <p>
                Get support from our dedicated team to land your new job and join our network of
                successful graduates.
              </p>
            </li>
          </ol>
        </div>
      </div>
      <h2 className="text-center mt-8">Apply Now for our next cohort - only 24 spots available!</h2>
      <CourseScheduleList cohortSchedule={cohorts} />
      <SimpleBanner type="prose" BackgroundImage={buildImage(data.firstJobBackground)}>
        <BannerTitle>
          Helping you land your first job is the first step. Where most bootcamps end, we’re just
          getting started.
        </BannerTitle>
        <BookInfoCallButton
          text="Schedule an Info Call"
          icon={faPhone}
          className="hero__button pr-3 mt-5 lg:mt-10"
          location={location}
          size="lg"
        />
      </SimpleBanner>
      <section className="part-time-postgrad-benefit">
        <div className="part-time-postgrad-benefit__container">
          <h2 className="part-time-postgrad-benefit__headline">Land the job. Learn for Life.</h2>
          <p className="part-time-postgrad-benefit__paragraph">
            Learning doesn’t end when you land your first job. Launch Academy graduates enjoy
            continuous curriculum access and updates so they can keep their skills up-to-date for
            changing market needs. They also have access to our Career Services support, and best of
            all, an incredible alumni network - all for life!
          </p>

          {/* todo: add proof */}
        </div>
      </section>
      <div className="bg-teal pb-20 px-4">
        <div className="mx-4 md:pt-32 md:pb-8 constrained-container relative">
          <h2 className="mb-4 pt-8 md:mb-10">Flexible Tuition Options to Meet Your Needs</h2>
          <p className="mr-12 md:mr-96">
            All accepted students enroll with a $1,000 refundable deposit to save their seat. You
            will have a one-time option to defer to a future cohort prior to week 1 should you need
            more time to prepare. We offer $500 diversity scholarships for anyone who feels they
            have a background which is underrepresented in the software engineering field.
          </p>
          <div className="banner__accent-image banner__accent-image_top-right">
            <GatsbyImage image={getImage(data.darkMeeting)} alt="Launchers Meeting" />
          </div>
        </div>
        <section className="card-list-container relative">
          <CardList
            cardType="feature"
            className="double-gap"
            content={tuitionContentWithImages}
            numberOfColumns={tuitionColumns}
          />
          <div className="banner__accent-image opacity-30 absolute -left-72 -bottom-24 z-0 hidden lg:block">
            <GatsbyImage image={getImage(data.computingAccent)} alt="Coding at Computer" />
          </div>
        </section>
      </div>
      <section className="part-time-closing-benefits">
        <div className="part-time-closing-benefits__container">
          <h2 className="part-time-closing-benefits__headline">
            Revolutionize your career with practical coding skills
          </h2>
          <div className="part-time-closing-benefits__content-container">
            <div className="part-time-closing-benefits__content">
              <p>
                Gear up for your tech transformation. Upon completion of our program, you’ll be able
                to:
              </p>
              <ul className="part-time-closing-benefits__list">
                <li>Build dynamic web applications from scratch</li>
                <li>Ace technical interviews with confidence</li>
                <li>Collaborate effectively in agile development teams</li>
                <li>Stay updated with the latest industry trends</li>
                <li>Land a high-paying job in the thriving field of software development</li>
              </ul>
              <p>Ready to write your success story in code?</p>
              <p className="part-time-closing-benefits__button-container">
                <Button
                  to={Configuration.applicationBaseUrl}
                  size="lg"
                  text="Apply Today"
                  icon={faChevronRight}
                />
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="faq-list container z-10 bg-white constrained-container">
        <h2 className="faq-list__header">Frequently Asked Questions</h2>
        <div className="faq-list-container">
          <FaqList
            questions={(data.faqSet?.frequently_asked_questions || []).map((question) => {
              return {
                title: question.question,
                description: <ReactMarkdown>{question.answer.data.answer}</ReactMarkdown>,
                id: question.id,
              }
            })}
          />
        </div>
      </div>
      <section className="bg-secondary-brand">
        <div className="hero">
          <div className="hero__supporting-content p-10">
            <h2 className="hero__image_title pb-10">Ready to launch your new career?</h2>
            <div className="hero__button-container">
              <BookInfoCallButton
                text="Schedule an Info Call"
                icon={faPhone}
                className="banner__button pr-3"
                location={location}
                size="lg"
              />
            </div>
            <div className="hero__button-container">
              <SyllabusModalButton
                text="Get the Syllabus"
                location={location}
                icon={faChevronRight}
                size="lg"
                placement="banner"
                secondary
              />
            </div>
          </div>
          <div className="hero__image-column md:my-16">
            <div className="hero__image-container relative">
              <GatsbyImage
                image={getImage(data.launchAcademyShirts)}
                alt="Launch Academy T-Shirts"
                className="hero__accent-top-right"
              />
              <GatsbyImage
                image={getImage(data.groupPicImage)}
                className="hero__image hero__image_sm z-10 shadow-md"
                alt="Group picture in from of Launch Academy logo"
              />
              <GatsbyImage
                image={getImage(data.collaboratingBackground)}
                className="hero__accent-bottom-left"
                alt="Collaborating on software"
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PartTimeOnline

export const pageQuery = graphql`
  query {
    topLeftHeader: file(
      relativePath: { regex: "/programs/part-time-online-page/watching-virtual-lecture.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          breakpoints: [210, 630]
          blurredOptions: { toFormat: WEBP }
          placeholder: BLURRED
        )
      }
    }
    computingAccent: file(
      relativePath: { regex: "/programs/part-time-online-page/topLeftHeader.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 690, height: 300)
      }
    }
    darkMeeting: file(relativePath: { regex: "/dark-meeting/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 180, height: 250)
      }
    }
    frustratedKnowledgeWorker: file(
      relativePath: { regex: "/programs/part-time-online-page/frustrated-knowledge-worker.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [450, 740])
      }
    }
    professionalAtDesk: file(
      relativePath: { regex: "/programs/part-time-online-page/professional-at-desk.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [450, 740])
      }
    }
    handsOn: file(
      relativePath: { regex: "/programs/part-time-online-page/coding-html-on-laptop.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [450, 740])
      }
    }
    sessions: file(
      relativePath: {
        regex: "/programs/part-time-online-page/live-virtual-session-with-coffee.jpg/"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    outcomes: file(
      relativePath: { regex: "/programs/part-time-online-page/coding-screenshot.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [310, 630])
      }
    }
    fromHome: file(
      relativePath: { regex: "/programs/part-time-online-page/learning-from-home-laptop.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    careerServices: file(
      relativePath: { regex: "/programs/part-time-online-page/alex-schwartz.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    order: file(relativePath: { regex: "/programs/part-time-online-page/order.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    tech: file(relativePath: { regex: "/programs/part-time-online-page/tech.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    instructors: file(
      relativePath: { regex: "/programs/part-time-online-page/agileCurriculumBackground.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    practice: file(relativePath: { regex: "/programs/part-time-online-page/topLeftHeader.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    job: file(relativePath: { regex: "/programs/part-time-online-page/job.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    employers: file(relativePath: { regex: "/programs/part-time-online-page/employers.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    agileCurriculumBackground: file(
      relativePath: { regex: "/programs/part-time-online-page/agileCurriculumBackground.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    coursePrepImage: file(
      relativePath: { regex: "/programs/part-time-online-page/coursePrepImage.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 720], width: 720)
      }
    }
    collaboratingBackground: file(
      relativePath: { regex: "/index-page/collaboratingBackground.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 220, height: 230)
      }
    }
    launchAcademyShirts: file(relativePath: { regex: "/launch-academy-shirts.png/" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 390, height: 370)
      }
    }
    codingFundamentalsImage: file(
      relativePath: { regex: "/programs/part-time-online-page/codingFundamentalsImage.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 720], width: 720)
      }
    }
    immersiveImage: file(
      relativePath: { regex: "/programs/part-time-online-page/immersiveImage.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 466], aspectRatio: 0.85, width: 466)
      }
    }
    careerAdvancementImage: file(
      relativePath: { regex: "/programs/part-time-online-page/careerAdvancementImage.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          breakpoints: [210, 720]
          aspectRatio: 0.9
          transformOptions: {}
          width: 720
        )
      }
    }
    capstoneProject: file(
      relativePath: { regex: "/programs/part-time-online-page/capstoneProject.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    teamProject: file(relativePath: { regex: "/programs/part-time-online-page/teamProject.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    firstJobBackground: file(
      relativePath: { regex: "/programs/part-time-online-page/firstJobBackground.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    profileImageOne: file(
      relativePath: { regex: "/programs/part-time-online-page/profileImageOne.png/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 300, height: 300)
      }
    }
    profileImageTwo: file(
      relativePath: { regex: "/programs/part-time-online-page/profileImageTwo.png/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 300, height: 300)
      }
    }
    profileImageThree: file(
      relativePath: { regex: "/programs/part-time-online-page/profileImageThree.png/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 300, height: 300)
      }
    }
    profileImageFour: file(
      relativePath: { regex: "/programs/part-time-online-page/profileImageFour.png/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 300, height: 300)
      }
    }
    upfront: file(relativePath: { regex: "/programs/part-time-online-page/upfront.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    afterGraduate: file(
      relativePath: { regex: "/programs/part-time-online-page/afterGraduate.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    topProgram: file(relativePath: { regex: "/programs/part-time-online-page/topProgram.jpg/" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    groupPicImage: file(
      relativePath: { regex: "/programs/part-time-online-page/groupPicImage.jpg/" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, breakpoints: [210, 630])
      }
    }
    launcherSet: strapiFeaturedLauncherSet(name: { eq: "program" }) {
      id
      featured_launchers {
        firstName
        currentJobTitle
        currentEmployer
        lastName
        headshot {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FIXED, width: 300, height: 300)
            }
          }
        }
      }
    }
    teamMemberSet: strapiTeamSet(name: { eq: "program" }) {
      team_members {
        bio
        headshot {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FIXED
                width: 220
                height: 220
                transformOptions: { grayscale: true }
              )
            }
          }
        }
        id
        jobTitle
        lastName
        firstName
      }
    }
    faqSet: strapiFrequentlyAskedQuestionSet(name: { eq: "part-time-virtual" }) {
      frequently_asked_questions {
        id
        answer {
          data {
            answer
          }
        }
        question
      }
    }
    virtualCohorts: allStrapiVirtualCohort(
      sort: { order: ASC, fields: cohortBegins }
      filter: { showOnSite: { eq: true } }
    ) {
      nodes {
        name
        enrollmentEnds
        cohortBegins
        cohortEnds
      }
    }
  }
`
