import { subDays, addDays } from "date-fns"

import Configuration from "../../Configuration"

export const collaborationContent = [
  {
    id: "capstoneProject",
    altText: "Teacher helping two students",
    header: "Scheduled and Recorded Live Sessions",
    body:
      "Learn collaboratively and in real-time, alongside your instructors and peers. If you can't make it, you can catch the recording.",
  },
  {
    id: "teamProject",
    altText: "Two students collaborating on whiteboarding problem",
    header: "Team Project",
    body:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Proin sagittis nisl rhoncus mattis rhoncus urna neque viverra. Donec ultrices tincidunt arcu non sodales. Molestie a iaculis at erat pellentesque adipiscing. Justo laoreet sit amet cursus sit amet dictum sit. Pretium fusce id velit ut tortor. Maecenas volutpat blandit aliquam etiam erat.",
  },
]

export const courseContent = [
  {
    id: "sessions",
    altText: "Live sessions",
    header: "Scheduled and Recorded Live Sessions",
    body:
      "Learn collaboratively and in real-time, alongside your instructors and peers. If you can't make it, you can catch the recording.",
  },
  {
    id: "handsOn",
    altText: "Teacher drawing diagram on whiteboard",
    header: "Hands on, challenge-based curriculum",
    body:
      "Understanding how technology works requires a learn-by-building approach. Through daily projects, regular workshops, and your personal capstone app, you’ll build skills sought by hiring managers.",
  },
  {
    id: "order",
    altText: "Learn the right things, in the right order",
    header: "Learn the right things, in the right order",
    body:
      "You wouldn’t begin building a house by constructing the roof. Chronologically optimized learning is no different. Starting with time-tested fundamentals adds rocket fuel to your ultimate learning trajectory.",
  },
  {
    id: "fromHome",
    altText: "Learn from home, but not alone",
    header: "Learn from home, but not alone",
    body:
      "While you can study from anywhere that has a solid internet connection and a quiet place to concentrate, you will be learning in a small cohort for  peer support and interaction",
  },
  {
    id: "outcomes",
    altText: "",
    header: "Outcomes that match the technical skills hiring managers are looking for",
    body:
      "Hiring managers inform curriculum enhancements every 90 days. With every cohort, we're updating the curriculum to ensure grads enter job interviews with the skills hiring companies want.",
  },
  {
    id: "careerServices",
    altText: "Remarkable career services",
    header: "A Career Services Team Behind 1000+ New Jobs",
    body: "Get the support you need to land the job with our proven career services team.",
  },
]

export const tuitionContent = [
  {
    id: "upfront",
    altText: "Image of lecture",
    header: "Pay upfront",
    body:
      "Try before you buy. Pay your full tuition in week 2 of the program. Your deposit is fully refundable anytime prior to this date.",
  },
  {
    id: "afterGraduate",
    altText: "Three women collaborating on laptops",
    header: "Pay after you graduate",
    body:
      'Defer tuition until 3 months after graduation through our loan partner, Ascent. Calculate payments and <a href="https://partner.ascentfunding.com/launchacademy/">learn more</a>. Includes options for a cost of living stipend for as much as  $6,000.',
  },
]

export const courseColumns = 3
export const textColumns = 2
export const tuitionColumns = 3
