import React, { FC } from "react"

import { Button } from "@launchacademy/voyager"

import { HalfPageParagraphProps } from "./HalfPageParagraph.d"
import "./css/halfPageParagraph.css"

export const HalfPageParagraph: FC<HalfPageParagraphProps> = ({
  title,
  text,
  subtitle,
  icon,
  className,
}) => (
  <section className={`half-page-paragraph ${className}`}>
    <div className="half-page-paragraph__subtitle">Launch Your Career</div>
    <div className="half-page-paragraph__title">{title}</div>
    <div className="half-page-paragraph__text">{text}</div>
    <div className="half-page-paragraph__action-subtitle">{subtitle}</div>
    <div>
      <Button to="#" text="Apply Today" icon={icon} className="hero_button mt-10" size="lg" />
    </div>
  </section>
)
